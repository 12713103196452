.about-container {
    padding: 0 14px;
    box-sizing: border-box;
    margin-top: 5rem;
    text-align: center;
}

.about-image-container {
    width: 11rem;
    height: 11rem;
    margin: 0 auto;
    border: 1px solid grey;
    border-radius: 50%;
    margin-top: 2rem;
    background-color: #4FBDBA;
}

.about-image {
    width: 90%;
}

.about-image-container:hover {
    border: solid 0.34rem rgba(255, 255, 255, 0.14);
    background-clip: content-box;
    transition: transform 0.6s ease;
    transform: scale(1.2);
}

.about-text {
    line-height: 1.46;
    letter-spacing: 1px;
    margin-top: 1rem;
    font-family: 'Montserrat',
        sans-serif;
}

.about-text-name {
    margin-top: 1rem;
    font-family: 'Arvo',
        serif;
    font-size: larger;
    color: #4FBDBA;
}


.about-text-container a {
    text-decoration: none;
    border: 2px solid #4FBDBA;
    padding: 0.5rem;
    color: #4FBDBA;
}

.about-text-container i {
    color: grey;
}

.about-resume-button {
    padding-bottom: 5rem;
    padding-top: 3rem;
    font-family: 'Montserrat',
        sans-serif;
    font-size: 0.7rem;

}

.about-resume-button a:hover {
    opacity: 0.5;
}

.about-hr {
    border-bottom: none;
    border-color: #4FBDBA;
    width: 50%;
}

.fonts {
    margin-top: 1.2rem;
    text-align: end;
}

.fa {
    text-decoration: none;
    color: white;
    margin-left: 1rem;
    font-size: 1rem;

}

h3 {
    font-family: 'Arvo',
        serif;
    color: #4FBDBA;
}

.about-hr {
    margin-top: 8.5srem;
}

.services-container {
    text-align: center;
}

.services-div {
    padding: 0 1rem;
}

.services-heading {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 1.1px;
}

.services {
    background-color: #35858B;
    color: #072227;
    padding: 1rem 0;
    margin-bottom: 1rem;
    /*********************** animation ***********************/
    opacity: 0;
    transform: Scale(1.2);
}

.services-img {
    width: 25%;
}

.services-p {
    text-align: center;
    line-height: 1.2;
    letter-spacing: 1.1px;
    font-weight: 600;

}


.services-stacks {
    margin-bottom: 0;
    color: #fff;
    text-decoration: underline;
}

.works {
    width: 90%;
    box-sizing: border-box;
    line-height: 1.2;
    letter-spacing: 1.1px;
    background-color: #01201f;
    margin-bottom: 1rem;
    flex: 1;
}

.works-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.works-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.works-content {
    width: 94%;
    margin: 0 auto;
    min-height: 10rem;
}

.works-description {
    font-family: 'Montserrat',
        sans-serif;
}

.works-hr {
    margin-top: 3rem;
}

#works-link,
#services-link {
    display: block;
    position: relative;
    top: -55px;
    visibility: hidden;
}

.works-name-p {
    text-align: center;
    border: 2px solid red;
}

.works-name {
    /* border: 2px solid red; */
    color: #35858B;

    font-family: 'Montserrat',
        sans-serif;

}

.works-image {
    align-self: flex-start;
}

.works-image img {
    width: 100%;
    opacity: 0.5;
}

.works-image img:hover {
    opacity: 1
}

.works-project-container {
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width:750px) {
    .about-hr {
        margin-top: 0;
    }

    .about-container {
        display: flex;
        height: 100vh;
        margin-top: 0;
        justify-content: center;
        align-items: center;
    }

    .about-text-container {
        width: 50%;
        /* padding-top: 2rem; */
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .about-image-container {
        margin-bottom: 5rem;
    }



    .about-image-container {
        margin-right: 0;
        margin-left: 0;
    }

    .services {
        width: 45%;
    }

    .services-div {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .works {
        display: flex;
        justify-content: center;
        background-color: #072227;

    }

    .works-reverse {
        flex-direction: row-reverse;
    }

    .works-content {
        width: 30%;
        background-color: #01201f;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 4rem;
        box-sizing: border-box;
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .works-image {
        width: 50%;
    }

    .works-image img {

        opacity: 0.5;
    }
}


@media (prefers-reduced-motion:no-preference) {

    .works,
    .services {
        transition: opacity 1s ease, transform 1.5s ease;
    }
}

.works-transition {
    opacity: 1;
    transform: none;
}