nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #05160a;
    font-family: 'Arvo',
        serif;
    font-size: small;
}



.logo-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-top: 10px;
    background-color: #4FBDBA;
    border-radius: 50%;
}


.navbar-container {
    padding: 0 10px;
    display: flex;
    width: 90%;
    margin: 0 auto;
    max-width: 1000px;
    justify-content: space-between;
}

.navbar-link {
    text-decoration: none;
    color: white;
    margin-left: 13px;
}

.navbar-link:hover {
    text-decoration: underline;
    text-decoration-color: #4FBDBA;
    text-underline-offset: 5px;


}

.navbar-link-container {
    padding-top: 20px;
}

@media screen and (min-width:750px) {
    /* .navbar-container {
        justify-content: space-evenly;
    }

    .navbar-link {
        margin-left: 22px;

    } */
}