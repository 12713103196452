*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background-color: #072227;
    color: white;
    margin: 0;
}