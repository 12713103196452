dfn {
    font-family: 'Arvo',
        serif;
    font-size: large;
    color: #4FBDBA;
}

.home-content p {
    font-family: 'Montserrat',
        sans-serif;
    font-size: 0.89rem;
}

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.3rem;
    min-height: 95vh;

    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    align-items: center;
}

.home-definition {
    text-align: center;
}

.home-image {
    height: 8rem;
    width: 8rem;
    align-self: center;
    background-color: #4FBDBA;
    border-radius: 50%;
    text-align: center;
}

.home-image img {
    height: 95%;
    width: 95%;
}

.welcome-button {
    text-decoration: none;
    color: #4FBDBA;
    font-family: 'Montserrat',
        sans-serif;
    font-size: 0.7rem;
}

.welcome-button:hover {
    opacity: 0.5;

}

.welcome-button-container {
    border-radius: 0.5rem;
    padding: 0.7rem;
    border: 2px solid #4FBDBA;
}



.welcome-container {
    display: flex;
    flex-direction: column;
    padding-left: 13px;
    padding-right: 10px;
    box-sizing: border-box;
}

.welcome-container p {
    line-height: 1.7;
    letter-spacing: 1px;
}

.fab {
    text-decoration: none;
    color: black;
    margin-left: 1.4rem;
    font-size: 1.2rem;
}

.home-fonts {
    text-align: center;
    width: 60%;
    margin-right: 1rem;
}


@media screen and (min-width:750px) {
    .home-image {
        height: 10rem;
        width: 10rem;
    }

    .home-content {
        width: 50%;
        margin: 0 auto;
    }

    .home-definition {
        /* border: 2px solid yellow; */
        text-align: center;
    }
}